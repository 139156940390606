import { useState, useEffect } from 'react';
import { URLInput } from '../../components/URLInput/URLInput';
import { ConversionCard } from '../../components/ConversionCard/ConversionCard';
import { FormatSelector } from '../../components/FormatSelector/FormatSelector';
import { DisclaimerModal } from '../../components/DisclaimerModal/DisclaimerModal';
import { type FormatType, type VideoQualityOption } from '../../types/conversion';
import styles from '../../styles/Home.module.css';

export const Home = () => {
  const [disclaimerAccepted, setDisclaimerAccepted] = useState(() => {
    return localStorage.getItem('disclaimerAccepted') === 'true';
  });
  const [conversionStatus, setConversionStatus] = useState<'idle' | 'converting' | 'done' | 'error'>('idle');
  const [selectedFormat, setSelectedFormat] = useState<FormatType>('mp3');
  const [selectedQuality, setSelectedQuality] = useState<string>('');
  const [videoQualities, setVideoQualities] = useState<VideoQualityOption[]>([]);
  const [videoInfo, setVideoInfo] = useState<{
    title?: string;
    thumbnail?: string;
    duration?: string;
    downloadUrl?: string;
    error?: string;
  }>({});

  useEffect(() => {
    if (disclaimerAccepted) {
      localStorage.setItem('disclaimerAccepted', 'true');
    }
  }, [disclaimerAccepted]);

  const handleURLSubmit = async (url: string) => {
    try {
      setConversionStatus('converting');
      setVideoInfo({});
      setVideoQualities([]);

      const response = await fetch(`${import.meta.env.VITE_API_URL}/download?url=${encodeURIComponent(url)}`);
      const data = await response.json();

      if ('error' in data) {
        throw new Error(data.error);
      }

      const videoId = data.url.split('/').pop() || '';
      const thumbnail = data.details?.thumbnails?.[0]?.url || 
                       `https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`;
      const title = data.details?.title || 'YouTube Video';
      
      if (selectedFormat === 'mp3') {
        const audioFormats = data.info
          .filter((format: any) => format.audioBitrate)
          .sort((a: any, b: any) => (b.audioBitrate || 0) - (a.audioBitrate || 0));

        if (audioFormats.length === 0) {
          throw new Error('No audio format available');
        }

        setVideoInfo({
          title,
          thumbnail,
          downloadUrl: `${audioFormats[0].url}&video_id=${videoId}`
        });
      } else {
        const videoFormats = data.info
          .filter((format: any) => format.qualityLabel && format.container === 'mp4')
          .sort((a: any, b: any) => {
            const getPixels = (quality: string = '') => {
              const match = quality.match(/(\d+)p/);
              return match ? parseInt(match[1]) : 0;
            };
            return getPixels(b.qualityLabel) - getPixels(a.qualityLabel);
          })
          .slice(0, 4)
          .map((format: any) => ({
            label: `${format.qualityLabel} - MP4`,
            format: {
              ...format,
              url: `${format.url}&video_id=${videoId}`
            }
          }));

        if (videoFormats.length === 0) {
          throw new Error('No video format available');
        }

        setVideoQualities(videoFormats);
        setSelectedQuality(videoFormats[0].format.url);
        setVideoInfo({
          title,
          thumbnail,
          downloadUrl: videoFormats[0].format.url
        });
      }
      
      setConversionStatus('done');
    } catch (error) {
      setConversionStatus('error');
      setVideoInfo({
        error: error instanceof Error ? error.message : 'An unexpected error occurred'
      });
    }
  };

  const handleFormatChange = (format: FormatType) => {
    setSelectedFormat(format);
    if (format === 'mp4' && videoQualities.length > 0) {
      setSelectedQuality(videoQualities[0].format.url);
      setVideoInfo(prev => ({
        ...prev,
        downloadUrl: videoQualities[0].format.url
      }));
    }
  };

  const handleQualityChange = (qualityUrl: string) => {
    setSelectedQuality(qualityUrl);
    setVideoInfo(prev => ({
      ...prev,
      downloadUrl: qualityUrl
    }));
  };

  const handleRetry = () => {
    setConversionStatus('idle');
    setVideoInfo({});
    setVideoQualities([]);
  };

  const handleDisclaimerAccept = () => {
    setDisclaimerAccepted(true);
  };

  return (
    <>
      <div className={styles.logoContainer}>
        <img 
          src="/images/ViTube.title.nobg.png" 
          alt="ViTube Logo" 
          className={styles.logo}
        />
      </div>
      <h2 className={`${styles.title} yeezy-font`}>
        Convert YouTube Videos
      </h2>
      
      {disclaimerAccepted ? (
        <>
          <FormatSelector
            selectedFormat={selectedFormat}
            onFormatChange={handleFormatChange}
            videoQualities={videoQualities}
            selectedQuality={selectedQuality}
            onQualityChange={handleQualityChange}
            disabled={conversionStatus === 'converting'}
          />

          <URLInput 
            onSubmit={handleURLSubmit} 
            isLoading={conversionStatus === 'converting'} 
            error={videoInfo.error}
          />

          <p className={styles.secondaryDisclaimer}>
            Use at your own risk
          </p>

          {conversionStatus !== 'idle' && (
            <ConversionCard 
              {...videoInfo}
              status={conversionStatus}
              onRetry={handleRetry}
              format={selectedFormat}
              quality={videoQualities.find(q => q.format.url === selectedQuality)?.label}
            />
          )}
        </>
      ) : (
        <DisclaimerModal 
          isOpen={true}
          onAccept={handleDisclaimerAccept}
        />
      )}
    </>
  );
};