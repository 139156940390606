import { useState, useRef, useEffect } from 'react';
import { type ConversionCardProps } from './ConversionCard.types';
import { Button } from '../Button/Button';
import styles from '../../styles/ConversionCard.module.css';

export const ConversionCard = ({
    title,
    thumbnail,
    downloadUrl,
    status,
    error,
    onRetry,
    format = 'mp3',
    quality
}: ConversionCardProps) => {
    const [downloadError, setDownloadError] = useState<string>('');
    const [downloadStatus, setDownloadStatus] = useState<'idle' | 'downloading' | 'complete'>('idle');
    const abortControllerRef = useRef<AbortController | null>(null);

    const handleDownload = async () => {
        if (!downloadUrl) return;

        try {
            setDownloadStatus('downloading');
            setDownloadError('');

            const itag = format === 'mp4' ? downloadUrl.match(/itag=(\d+)/)?.[1] : '';
            const videoUrl = new URL(downloadUrl).searchParams.get('video_id') || '';
            const youtubeUrl = `https://youtube.com/watch?v=${videoUrl}`;
            const filename = `${title || 'video'}.${format}`;
            
            const downloadLink = `${import.meta.env.VITE_API_URL}/download-file?url=${encodeURIComponent(youtubeUrl)}&format=${format}&title=${format === 'mp4' ? itag : encodeURIComponent(title || 'audio')}`;

            abortControllerRef.current = new AbortController();

            const response = await fetch(downloadLink, {
                signal: abortControllerRef.current.signal
            });

            if (!response.ok) {
                if (response.status === 404) {
                    throw new Error('Video not found or no longer available');
                } else if (response.status === 403) {
                    throw new Error('This video is not available for download');
                } else if (response.status >= 500) {
                    throw new Error('Server is experiencing issues. Please try again later');
                }
                throw new Error('Download failed');
            }

            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            
            const link = document.createElement('a');
            link.href = url;
            link.download = filename;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);

            setDownloadStatus('complete');
            setTimeout(() => {
                setDownloadStatus('idle');
            }, 5000);
            
        } catch (err) {
            if (err instanceof Error) {
                if (err.name === 'AbortError') {
                    setDownloadError('Download cancelled');
                } else if (err.message.includes('network') || err.message === 'Failed to fetch') {
                    setDownloadError('Connection error. Please check your internet and try again');
                } else {
                    setDownloadError(err.message);
                }
            } else {
                setDownloadError('Failed to download. Please try converting again.');
            }
            setDownloadStatus('idle');
            console.error('Download error:', err);
        } finally {
            abortControllerRef.current = null;
        }
    };

    // Cleanup on unmount or format change
    useEffect(() => {
        return () => {
            if (abortControllerRef.current) {
                abortControllerRef.current.abort();
            }
        };
    }, [format]);

    if (status === 'error') {
        return (
            <div className={`${styles.card} ${styles.error}`}>
                <i className="fa-solid fa-circle-exclamation" style={{ color: '#ef4444', fontSize: '1.5rem' }} aria-hidden="true"></i>
                <p className={styles.errorMessage}>
                    {error === 'Failed to fetch' ? 
                        'Unable to connect to the server. Please check your internet connection and try again.' 
                        : error || 'An unexpected error occurred. Please try again.'}
                </p>
                <Button 
                    onClick={onRetry}
                    variant="secondary"
                >
                    Try Again
                </Button>
            </div>
        );
    }

    return (
        <div className={styles.card}>
            {thumbnail && (
                <div className={styles.thumbnailContainer}>
                    <img src={thumbnail} alt={title} className={styles.thumbnail} />
                </div>
            )}
            <div className={styles.info}>
                {title && <h3 className={styles.title}>{title}</h3>}
                {status === 'converting' ? (
                    <div className={styles.converting}>
                        <div className={styles.spinner}></div>
                        <p>Converting...</p>
                    </div>
                ) : (
                    <div className={styles.downloadSection}>
                        <p className={styles.formatInfo}>
                            Format: {format.toUpperCase()}
                            {quality && ` - ${quality}`}
                        </p>
                        {downloadError ? (
                            <div className={styles.error}>
                                <p className={styles.errorMessage}>
                                    {downloadError === 'Failed to fetch' ?
                                        'Download failed. Please check your internet connection and try again.'
                                        : downloadError}
                                </p>
                                <Button 
                                    onClick={onRetry}
                                    variant="secondary"
                                >
                                    Try Again
                                </Button>
                            </div>
                        ) : (
                            <>
                                <Button
                                    onClick={handleDownload}
                                    className={`${styles.downloadButton} ${downloadStatus !== 'idle' ? styles.downloading : ''}`}
                                    disabled={!downloadUrl || downloadStatus !== 'idle'}
                                >
                                    {downloadStatus === 'downloading' ? (
                                        <>
                                            <span className={styles.downloadSpinner}></span>
                                            Downloading...
                                        </>
                                    ) : downloadStatus === 'complete' ? (
                                        <>
                                            <span className={styles.downloadCheck}>✓</span>
                                            Download Complete
                                        </>
                                    ) : (
                                        `Download ${format.toUpperCase()}`
                                    )}
                                </Button>
                                {downloadStatus === 'downloading' && (
                                    <p className={styles.downloadMessage}>
                                        Download in progress...
                                    </p>
                                )}
                            </>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};