import { BrowserRouter } from 'react-router-dom';
import { NavBar } from './components/NavBar/NavBar';
import { Footer } from './components/Footer/Footer';
import { Routes } from './routes/Routes';
import styles from './styles/App.module.css';

export const App = () => {
  return (
    <BrowserRouter>
      <div className={styles.container}>
        <div className={styles.mainContent}>
          <NavBar 
            title="Youtube Converter" 
            githubUrl="https://github.com/vass-k"
          />
          
          <main className={styles.main}>
            <Routes />
          </main>

          <Footer />
        </div>
      </div>
    </BrowserRouter>
  );
};