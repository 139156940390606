import { useEffect, useCallback } from 'react';
import { type FormatSelectorProps } from './FormatSelector.types';
import styles from '../../styles/FormatSelector.module.css';

export const FormatSelector = ({
    selectedFormat,
    onFormatChange,
    videoQualities,
    selectedQuality,
    onQualityChange,
    disabled
}: FormatSelectorProps) => {
    const handleFormatSwitch = useCallback((format: 'mp3' | 'mp4') => {
        if (!disabled) {
            onFormatChange(format);
        }
    }, [disabled, onFormatChange]);

    useEffect(() => {
        const handleKeyPress = (e: KeyboardEvent) => {
            if (disabled) return;
            
            if (e.key === '3' && e.altKey) {
                handleFormatSwitch('mp3');
            } else if (e.key === '4' && e.altKey) {
                handleFormatSwitch('mp4');
            }
        };

        window.addEventListener('keydown', handleKeyPress);
        return () => {
            window.addEventListener('keydown', handleKeyPress);
        };
    }, [disabled, handleFormatSwitch]);

    return (
        <div className={styles.container}>
            <div className={styles.formatGroup}>
                <button
                    className={`${styles.formatButton} ${selectedFormat === 'mp3' ? styles.selected : ''}`}
                    onClick={() => handleFormatSwitch('mp3')}
                    disabled={disabled}
                    aria-pressed={selectedFormat === 'mp3'}
                    title="Convert to MP3 (Alt+3)"
                >
                    MP3 (Audio)
                </button>
                <button
                    className={`${styles.formatButton} ${selectedFormat === 'mp4' ? styles.selected : ''}`}
                    onClick={() => handleFormatSwitch('mp4')}
                    disabled={disabled}
                    aria-pressed={selectedFormat === 'mp4'}
                    title="Convert to MP4 (Alt+4)"
                >
                    MP4 (Video)
                </button>
            </div>

            {selectedFormat === 'mp4' && videoQualities && videoQualities.length > 0 && (
                <select
                    className={styles.qualitySelect}
                    value={selectedQuality}
                    onChange={(e) => onQualityChange?.(e.target.value)}
                    disabled={disabled}
                    aria-label="Select video quality"
                >
                    {videoQualities.map((quality, index) => (
                        <option key={index} value={quality.format.url}>
                            {quality.label}
                        </option>
                    ))}
                </select>
            )}
        </div>
    );
};