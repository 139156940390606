import { lazy, Suspense } from 'react';
import { Routes as RouterRoutes, Route } from 'react-router-dom';
import { Home } from '../pages/Home/Home';
import { PageLoader } from '../components/PageLoader/PageLoader';
import { ErrorBoundary } from '../components/ErrorBoundary/ErrorBoundary';
import { ErrorTest } from '../components/ErrorTest/ErrorTest';

// Lazy load infrequently accessed components
const CopyrightPage = lazy(() => import('../pages/Legal/CopyrightPage').then(module => ({ default: module.CopyrightPage })));
const TermsPage = lazy(() => import('../pages/Legal/TermsPage').then(module => ({ default: module.TermsPage })));
const PrivacyPage = lazy(() => import('../pages/Legal/PrivacyPage').then(module => ({ default: module.PrivacyPage })));
const DisclaimerPage = lazy(() => import('../pages/Legal/DisclaimerPage').then(module => ({ default: module.DisclaimerPage })));
const NotFound = lazy(() => import('../pages/NotFound/NotFound').then(module => ({ default: module.NotFound })));

export const Routes = () => {
    return (
        <ErrorBoundary>
            <RouterRoutes>
                <Route path="/" element={<Home />} />
                <Route path="/error-test" element={<ErrorTest />} />
                <Route path="/legal/copyright" element={
                    <Suspense fallback={<PageLoader />}>
                        <CopyrightPage />
                    </Suspense>
                } />
                <Route path="/legal/terms" element={
                    <Suspense fallback={<PageLoader />}>
                        <TermsPage />
                    </Suspense>
                } />
                <Route path="/legal/privacy" element={
                    <Suspense fallback={<PageLoader />}>
                        <PrivacyPage />
                    </Suspense>
                } />
                <Route path="/legal/disclaimer" element={
                    <Suspense fallback={<PageLoader />}>
                        <DisclaimerPage />
                    </Suspense>
                } />
                <Route path="*" element={
                    <Suspense fallback={<PageLoader />}>
                        <NotFound />
                    </Suspense>
                } />
            </RouterRoutes>
        </ErrorBoundary>
    );
};