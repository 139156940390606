import { useState } from 'react';
import { type URLInputProps } from './URLInput.types';
import { Button } from '../Button/Button';
import styles from '../../styles/URLInput.module.css';

const YOUTUBE_URL_REGEX = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be)\/.+$/;

export const URLInput = ({ onSubmit, isLoading, error }: URLInputProps) => {
    const [url, setUrl] = useState('');
    const [validationError, setValidationError] = useState('');

    const validateURL = (url: string): boolean => {
        if (!url) {
            setValidationError('Please enter a YouTube URL');
            return false;
        }
        if (!YOUTUBE_URL_REGEX.test(url)) {
            setValidationError('Please enter a valid YouTube URL');
            return false;
        }
        setValidationError('');
        return true;
    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        if (validateURL(url)) {
            onSubmit(url);
        }
    };

    const handleURLChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newUrl = e.target.value;
        setUrl(newUrl);
        if (validationError) {
            validateURL(newUrl);
        }
    };

    return (
        <form onSubmit={handleSubmit} className={styles.urlInput}>
            <div className={styles.container}>
                <div className={styles.inputGroup}>
                    <label htmlFor="youtube-url" className={styles.visuallyHidden}>
                        YouTube URL
                    </label>
                    <input
                        type="url"
                        value={url}
                        onChange={handleURLChange}
                        placeholder="Paste YouTube URL here..."
                        className={`${styles.input} ${(validationError || error) ? styles.error : ''}`}
                        required
                        aria-label="YouTube URL input"
                        aria-invalid={!!validationError || !!error}
                        id="youtube-url"
                        name="youtube-url"
                        autoComplete="off"
                    />
                    <Button type="submit" disabled={isLoading || !!validationError}>
                        {isLoading ? 'Converting...' : 'Convert'}
                    </Button>
                </div>
                {(validationError || error) && (
                    <p className={styles.errorText} role="alert">
                        {validationError || error}
                    </p>
                )}
            </div>
        </form>
    );
};