import { type NavBarProps } from './NavBar.types';
import styles from '../../styles/NavBar.module.css';

export const NavBar = ({ title, githubUrl }: NavBarProps) => {
    return (
        <nav className={styles.navbar}>
            <div className={styles.container}>
                <div className={styles.content}>
                    <h1 className={`${styles.title} yeezy-font`}>
                        {title}
                    </h1>
                    <a 
                        href={githubUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                        className={styles.githubLink}
                        aria-label="Visit GitHub repository"
                    >
                        <i className="fa-brands fa-github"></i>
                    </a>
                </div>
            </div>
        </nav>
    );
};