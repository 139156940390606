import { type DisclaimerModalProps } from './DisclaimerModal.types';
import { Button } from '../Button/Button';
import styles from '../../styles/DisclaimerModal.module.css';

export const DisclaimerModal = ({ isOpen, onAccept }: DisclaimerModalProps) => {
    if (!isOpen) return null;

    const handleTouchMove = (e: React.TouchEvent) => {
        e.preventDefault(); // Prevent scrolling behind modal on mobile
    };

    return (
        <div 
            className={styles.overlay}
            onTouchMove={handleTouchMove}
        >
            <div 
                className={styles.modal}
                role="dialog"
                aria-modal="true"
                aria-labelledby="disclaimer-title"
            >
                <div className={styles.titleContainer}>
                    <i className="fa-solid fa-triangle-exclamation" aria-hidden="true"></i>
                    <h2 id="disclaimer-title" className={`${styles.title} yeezy-font`}>
                        Important Disclaimer
                    </h2>
                </div>
                <div 
                    className={styles.content}
                    role="document"
                >
                    <div className={styles.warning}>
                        <p className={styles.warningMain}>
                            This tool may violate YouTube's Terms of Service
                        </p>
                        <p className={styles.warningRisk}>
                            Use at your own risk
                        </p>
                    </div>
                    <p className={styles.disclaimer}>
                        By proceeding, you agree to comply with all applicable laws and accept full responsibility for your actions.
                    </p>
                </div>
                <Button 
                    className={styles.button}
                    onClick={onAccept}
                    aria-label="Accept disclaimer"
                >
                    <i className="fa-solid fa-check" aria-hidden="true"></i>
                    I Understand & Accept
                </Button>
            </div>
        </div>
    );
};