import { type ButtonProps } from './Button.types';
import styles from '../../styles/Button.module.css';

export const Button = ({
    variant = 'primary',
    size = 'md',
    className,
    children,
    ...props
}: ButtonProps) => {
    return (
        <button
            className={`${styles.button} ${styles[variant]} ${styles[size]} ${className || ''}`}
            {...props}
        >
            {children}
        </button>
    );
};