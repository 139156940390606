import { Component, ErrorInfo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { type ErrorBoundaryProps, type ErrorBoundaryState } from './ErrorBoundary.types';
import styles from './ErrorBoundary.module.css';
import { Button } from '../Button/Button';

const withRouter = (Component: any) => {
    return (props: ErrorBoundaryProps) => {
        const navigate = useNavigate();
        const location = useLocation();
        return <Component {...props} navigate={navigate} location={location} />;
    };
};

class ErrorBoundaryBase extends Component<
    ErrorBoundaryProps & { 
        navigate: (path: string) => void;
        location: { pathname: string };
    }, 
    ErrorBoundaryState
> {
    public state: ErrorBoundaryState = {
        hasError: false
    };

    public static getDerivedStateFromError(error: Error): ErrorBoundaryState {
        return { hasError: true, error };
    }

    public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        console.error('Uncaught error:', error, errorInfo);
    }

    public componentDidUpdate(prevProps: ErrorBoundaryProps & { location: { pathname: string }}) {
        // Reset error state when the route changes
        if (this.state.hasError && prevProps.location.pathname !== this.props.location.pathname) {
            this.setState({ hasError: false, error: undefined });
        }
    }

    private handleNavigateHome = () => {
        this.props.navigate('/');
    };

    public render() {
        if (this.state.hasError) {
            return this.props.fallback || (
                <div className={styles.errorContainer} role="alert" aria-labelledby="error-title">
                    <div className={styles.errorContent}>
                        <i className="fa-solid fa-dragon" aria-hidden="true"></i>
                        <h1 id="error-title" className={styles.errorTitle}>Oops! Something went wrong!</h1>
                        <p className={styles.errorMessage}>
                            We're currently fighting a dragon. Don't worry, we've got this under control!
                        </p>
                        <Button 
                            onClick={this.handleNavigateHome}
                            className={styles.homeButton}
                            aria-label="Navigate back to home page"
                        >
                            Back to Home
                        </Button>
                    </div>
                </div>
            );
        }

        return this.props.children;
    }
}

export const ErrorBoundary = withRouter(ErrorBoundaryBase);