import { useState } from 'react';
import { Link } from 'react-router-dom';
import { type FooterProps } from './Footer.types';
import styles from '../../styles/Footer.module.css';

export const Footer = ({ className }: FooterProps) => {
    const [expandedFaq, setExpandedFaq] = useState<number | null>(null);

    const handleFaqClick = (index: number) => {
        setExpandedFaq(expandedFaq === index ? null : index);
    };

    const handleLinkClick = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    return (
        <footer className={`${styles.footer} ${className || ''}`}>
            <div className={styles.container}>
                <div className={styles.content}>
                    <div className={styles.section}>
                        <h2 className={styles.sectionTitle}>FAQ</h2>
                        <ul className={styles.faqList}>
                            {[
                                {
                                    question: 'Is this service free?',
                                    answer: 'Yes, ViTube Converter is completely free to use.'
                                },
                                {
                                    question: 'What formats are supported?',
                                    answer: 'We support MP3 (audio) and MP4 (video) with various quality options.'
                                },
                                {
                                    question: 'Are there any download limits?',
                                    answer: 'While there are no strict limits, please be mindful of fair usage.'
                                }
                            ].map((faq, index) => (
                                <li 
                                    key={index}
                                    className={`${styles.faqItem} ${expandedFaq === index ? styles.expanded : ''}`}
                                    onClick={() => handleFaqClick(index)}
                                >
                                    <p className={styles.question}>{faq.question}</p>
                                    <p className={styles.answer}>{faq.answer}</p>
                                </li>
                            ))}
                        </ul>
                    </div>

                    <div className={styles.section}>
                        <h2 className={styles.sectionTitle}>Legal</h2>
                        <ul className={styles.linksList}>
                            {[
                                { to: '/legal/terms', text: 'Terms of Service' },
                                { to: '/legal/privacy', text: 'Privacy Policy' },
                                { to: '/legal/copyright', text: 'Copyright Notice' },
                                { to: '/legal/disclaimer', text: 'Legal Disclaimer' }
                            ].map((link, index) => (
                                <li key={index}>
                                    <Link 
                                        to={link.to} 
                                        className={styles.link}
                                        onClick={handleLinkClick}
                                    >
                                        {link.text}
                                    </Link>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>

                <div className={styles.copyright}>
                    <p>© {new Date().getFullYear()} ViTube Converter. This is an educational project and not intended for commercial use.</p>
                </div>
            </div>
        </footer>
    );
};